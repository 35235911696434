import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import QrReader from "react-qr-reader";
import "./styled.css";

//service
import { getFunctions, readFunctions } from "../../services/qrCode";
//component
import Audio from "./audio";

import SweetAlert from "react-bootstrap-sweetalert";
import { audioList } from "./audioList";
import DownloadPDF from "./download";

export default function Qrcode() {
  const [user, setUser] = useState(null);
  const [functions, setFunctions] = useState();
  const [play, setPlay] = useState();
  const [data, setData] = useState();
  const [scan, setScan] = useState(false);
  const [searchParams] = useSearchParams();
  //state de alerta
  const [qrcodeExisting, setQrcodeExisting] = useState(false);
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [qrcodeNotFound, setQrcodeNotFound] = useState(false);
  const [fullQrcode, setFullQrcode] = useState(false);
  //qrcode reader button
  const [qrcodeReaderBtn, setQrcodeReaderBtn] = useState(true);

  async function scanQrcode(e) {
    await readFunctions(user.idPart, e, user.idEvent)
      .then((response) => {
        console.log(response);
        if (response.message === "AUDIO_YET_RELEASED") {
          setQrcodeExisting(true);
        } else if (response.message === "AUDIO_RELEASED") {
          setQrcodeRead(true);
        } else setQrcodeNotFound(true);
      })
      .catch((error) => console.log(error));
  }

  async function getAllFunctions() {
    if (user) {
      await getFunctions(user.idPart, user.idEvent)
        .then((response) => {
          setFunctions(response.data);
          if (
            response.data.length > 0 &&
            response.data.filter((e) => e.desbloqueado === true).length ===
            response.data.length
          ) {
            console.log("aqui");
            setFullQrcode(true);
            setQrcodeReaderBtn(false);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  useEffect(() => {
    if (window.location.search != "") {
      setUser({
        idEvent: searchParams.get("idEvent"),
        idPart: searchParams.get("idPart"),
        native: searchParams.get("native"),
      });
    }
  }, []);

  useEffect(() => {
    getAllFunctions();
  }, [user]);

  return (
    <div className="div-geral-qrcode">
      {qrcodeExisting && (
        <SweetAlert
          warning
          title="Já foi usado"
          onConfirm={() => {
            setQrcodeExisting(false);
          }}
        >
          Esse QRCode já foi usado!
        </SweetAlert>
      )}
      {qrcodeRead && (
        <SweetAlert
          success
          title="Desbloqueado"
          onConfirm={() => {
            setQrcodeExisting(false);
            setQrcodeRead(false);
            setScan(false);
            getAllFunctions();
          }}
        >
          Áudio desbloqueado com sucesso!
        </SweetAlert>
      )}
      {qrcodeNotFound && (
        <SweetAlert
          danger
          title="Não Encontrado"
          onConfirm={() => {
            setQrcodeNotFound(false);
          }}
        >
          QRCode não encontrado
        </SweetAlert>
      )}
      {fullQrcode && (
        <SweetAlert
          success
          title="Parabéns"
          onConfirm={() => {
            setFullQrcode(false);
          }}
        >
          Você liberou todos os áudios.
        </SweetAlert>
      )}
      <div className="card-qrcode">
        {qrcodeReaderBtn && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%"
            }}
          >
            <h1 className="reader-qrcode" onClick={() => setScan(true)}>
              Ler QRCode
            </h1>
            {scan && (
              <div className="div-qrcode">
                <QrReader
                  onScan={(e) => {
                    if (e) {
                      scanQrcode(e);
                    }
                  }}
                  style={{ width: "100%", heigth: "100px" }}
                />
                <span className="close-scan" onClick={() => setScan(false)}>
                  Fechar Scan
                </span>
              </div>
            )}
          </div>
        )}
        <div className="div-audios">
          {functions &&
            functions.map((e, i) => (
              <>
                <div key={i} style={{ display: 'flex', alignItems: 'bottom', justifyContent: 'center', color: 'lightGray' }}>
                  {audioList[i]?.title}
                </div>

                <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                  <Audio
                    user={user}
                    play={play}
                    setPlay={setPlay}
                    audio={audioList[i]?.linkAudio}
                    function={e}
                  />
                  {/* <a href={audioList[i].linkPdf} download>
                  <i
                    className="fas fa-file-pdf"
                  />
                </a> */}
                  <DownloadPDF
                    isEnable={e.desbloqueado}
                    user={user}
                    data={audioList[i]?.linkPdf}
                    type={"PDF"}
                    fileName={`PDF${i + 1}.pdf`}
                    title={`PDF${i + 1}.pdf`}
                  />
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
}
