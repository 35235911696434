import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import QrReader from "react-qr-reader";
import "./styled.css";

import SweetAlert from "react-bootstrap-sweetalert";
import { getFunctions, QRCodeApp } from "../../services/login";
import { createEarnedPoints } from '../../services/ranking';

const codeList = [
  { id: 'b052050e-1b0c-477b-8d97-e9dfd03de4de', description: 'PALESTRA1' },
  { id: '9231a10a-eed2-482e-ad64-dc679adafbae', description: 'PALESTRA2' },
  { id: 'f52e456c-dbfb-4965-8f87-032b43d596c6', description: 'PALESTRA3' },
  { id: '53b8bf1a-5e03-4d79-b6e1-1eb1573b48c2', description: 'PALESTRA4' },
  { id: '610153af-2785-4bc6-a4aa-c5afeb61175b', description: 'PALESTRA5' },
  { id: 'ac88384a-469e-454e-afb2-a72696ac84b4', description: 'PALESTRA6' }
]

export default function Senac() {
  const [user, setUser] = useState(null);
  const [scan, setScan] = useState(false);
  const [searchParams] = useSearchParams();
  //state de alerta
  const [qrcodeExisting, setQrcodeExisting] = useState(false);
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [qrcodeNotFound, setQrcodeNotFound] = useState(false);
  //state all qrcodes are read
  const [fullQrcode, setFullQrcode] = useState(false);

  async function scanQrcode(e) {
    const data = {
      participanteId: user.idPart,
      eventId: user.idEvent,
      functionId: e,
      points: 6,
    }
    await QRCodeApp({ data })
      .then(async (response) => {
        if (response.message === "FUNCTION_YET_RELEASED") {
          setQrcodeExisting(true);
        } else if (response.message === "FUNCTION_RELEASED") {
          setQrcodeRead(true);
          const code = getCode(e);
          const dataRanking = {
            code: code.description,
            positive: true,
            log: 'add points senac',
          }
          await createEarnedPoints(dataRanking, data?.eventId, data?.participanteId).then(response => {
            if (response.message === 'ADDED_TO_QUEUE') {
              // console.log('QUEUED')
            } else {
              // console.log('queue error')
            }
          })

        } else setQrcodeNotFound(true);
      })
      .catch((error) => console.log(error));
  }

  function getCode(id) {
    return codeList.filter(f => f.id === id)[0];
  }

  useEffect(() => {
    if (window.location.search !== "") {
      setUser({
        idEvent: searchParams.get("idEvent"),
        idPart: searchParams.get("idPart"),
      });
    }
  }, [searchParams]);

  const getAllFunctions = useCallback(async () => {
    if (user) {
      await getFunctions(user.idPart, user.idEvent)
        .then((response) => {
          if (response.data.length > 0 && response.data.every((e) => e.desbloqueado === true)) {
            setFullQrcode(true);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  useEffect(() => {
    getAllFunctions();
  }, [getAllFunctions]);

  return (
    <div className="div-geral-qrcode">
      {qrcodeExisting && (
        <SweetAlert
          warning
          title="Já foi usado"
          onConfirm={() => {
            setQrcodeExisting(false);
            setScan(false);
          }}
        >
          Esse QRCode já foi usado!
        </SweetAlert>
      )}
      {qrcodeRead && (
        <SweetAlert
          success
          title="Parabéns"
          onConfirm={() => {
            setQrcodeExisting(false);
            setQrcodeRead(false);
            setScan(false);
          }}
        >
          Você ganhou mais 6 pontos!
        </SweetAlert>
      )}
      {qrcodeNotFound && (
        <SweetAlert
          danger
          title="Não Encontrado"
          onConfirm={() => {
            setQrcodeNotFound(false);
            setScan(false);
          }}
        >
          QRCode não encontrado
        </SweetAlert>
      )}
      <div className="card-qrcode">
        {!fullQrcode ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%"
            }}
          >
            <h1 className="reader-qrcode-senac" onClick={() => setScan(true) > getAllFunctions()}>
              Ler QRCode
            </h1>
            {scan && (
              <div className="div-qrcode">
                <QrReader
                  onScan={(e) => {
                    if (e) {
                      scanQrcode(e);
                    }
                  }}
                  style={{ width: "100%", heigth: "100px" }}
                />
                <i className="close-scan" onClick={() => setScan(false)}>
                  Fechar Scan
                </i>
              </div>
            )}
            </div>
          ) : (
            <div>
              <h1 className="qrcode-msg">
                Parabéns, você já leu todos os QRCode's e ganhou todos os pontos!
              </h1>
            </div>
          )}
      </div>
    </div>
  );
}
