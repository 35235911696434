import React, { useState, useEffect } from "react";
import { isIOS } from "react-device-detect";
import { postDownload } from "../../services/qrCode";

import { v4 as uuidv4 } from 'uuid';

export default function DownloadPDF(props) {
  const id = uuidv4();

  async function send() {
    await postDownload(
      props.user?.idEvent,
      props.user?.idPart,
      id,
      props.fileName
    )
      .then((response) => {})
      .catch((error) => {
        // console.log("erro", error);
      });

    if (props.user.native) {
      // se tiver o paramêtro passado pelo app, baixa pelo app
      window.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: props.data,
          name: props.fileName,
        }),
        "*"
      );
      window.parent.ReactNativeWebView.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: props.data,
          name: props.fileName,
        })
      );
    } else {
      // se n tiver o parâmetro, baixa pelo web
      const link = document.createElement("a");
      link.download = props.fileName;
      link.target = "_blank";
      link.href = props.data;
      link.click();
    }
  }

  // useEffect(() => {
  //   if (isIOS) {
  //     if (props.type === "PDF") {
  //       setDownload(true);
  //     }
  //   }
  // }, []);

  return (
    <a
      style={{
        marginLeft: '10px',
        fontSize: '2rem',
        color: props.isEnable ? '#fff' : '#878787',
        pointerEvents: props.isEnable ? 'auto' : 'none',
      }}
      className="btn-download"
      // href={download ? props.data : null}
      // target={download ? "" : null}
      // rel="noreferrer"
      onClick={() => send()}
      // download={download ? props.fileName : null}
      title="Download PDF"
    >
      <i
        className="fas fa-file-pdf"
      />
    </a>
  );
}
