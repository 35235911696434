import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ Component }) {
  const auth = localStorage.getItem('authUser');

  return auth ? <Component /> : <Navigate to="/loginsenac" />;
}

export default PrivateRoute;
