export const audioList = [
  {
    linkPdf: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/6844bd0b-7ee5-44d7-aa80-0cd384bb1653.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295790&Signature=atnCYl7l9X8kpHFQo2dSPYGExUY%3D',
    linkAudio: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/7dcf115e-ae49-494f-8f11-c4ca180f4d46.m4a?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295337&Signature=xULC4PgEYf%2Fsvb1JEtTKoSsSui4%3D',
    title: '1972 - O Discurso de Marselha'
  },
  {
    linkPdf: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/6d1a9016-9d7c-4891-92c0-ecb86e2039cd.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295797&Signature=ZYCM19k4Cs7pWsybDjwfLZNfvLc%3D',
    linkAudio: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/34f2006d-ffaf-4344-8db0-3057cd9d50da.m4a?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092394417&Signature=LqlIpYU7RMUVmNuG2xx9p%2Fh1j58%3D',
    title: '2006 - O Primeiro Negócio'
  },
  {
    linkPdf: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/0f854e1b-e635-45eb-a05f-ead98ca530da.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295805&Signature=PPgMU%2BsR7u6XurVM%2FzVbcNdKax8%3D',
    linkAudio: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/2df01d0c-28cf-4f56-a05e-047dae6d4140.mp3?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092404217&Signature=3nw%2B48eGWQl%2FijbuVvEnHSwnW3E%3D',
    title: 'Desde 2006 - Nosso Legado'
  },
  {
    linkPdf: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/c2b8535a-9964-4c35-afc9-93da693aebd3.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295812&Signature=t88QP6qckUf3nSXF1a9hUkJ%2Fxvw%3D',
    linkAudio: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/c7f282a9-2937-4305-b61a-7c416a15fa9e.m4a?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092394435&Signature=wGB430PRqsfSPRi1fLKmoxPDbTg%3D',
    title: 'Desde 2019 - Alianças que mudam o futuro'
  },
  {
    linkPdf: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/2fa85614-4e08-4da3-8b20-1e05edc9eda1.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295818&Signature=hoL%2BboO9pkyPLOmSPgGBe4vbb6U%3D',
    linkAudio: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/5ff7631c-8ec9-4fc0-8add-f127380aef04.m4a?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092394455&Signature=pGCT2N8zOSY4Ecf%2BTl4HpHOa9zI%3D',
    title: '2020 - Nossos Avanços'
  },
  {
    linkPdf: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/8c7f764b-48dc-4f16-a62b-5e3ecc076bc4.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295825&Signature=eB%2BySST3wRq8qDN86n7BDLAw5ws%3D',
    linkAudio: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/5932d214-fa22-4552-86fb-f4c7c2a44d9d.mp3?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092403377&Signature=Hgi%2B1sYT1hL1PPDYwZtMWD22Itc%3D',
    title: 'Desde 2020 - Inovando com a Agricultura Regenerativa'
  },
  {
    linkPdf: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/78cec0cf-305a-4d74-8876-681d181e62db.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295833&Signature=UpvVCZ0059%2BM1P4sAqIgq7BjF9s%3D',
    linkAudio: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/ba19ee3f-3412-4343-8c76-c12501fc6275.m4a?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092394548&Signature=NG91k7V8mjt4UYV%2B7USB2mHKebY%3D',
    title: 'Desde 2020 - Plano de Aceleração Climática em Ação'
  },
  {
    linkPdf: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/f20cf684-260e-46bc-bbb9-206d529198b0.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295841&Signature=wte2VWLirwhylSuVrKZOf3NrBK8%3D',
    linkAudio: 'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/8a19cf1b-88e0-4997-9f06-cfe007717184.m4a?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2092295481&Signature=ZEEY7fFjqXu40Sgqe0%2BY%2FO5wc2s%3D',
    title: '2021 - Um ano de Certificações Pioneiras'
  }
];
