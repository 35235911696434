import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";

// import images
import logo from "../../assets/image/logoLogin.png";
import profile from "../../assets/image/icon.png";
import { loginForm } from "../../services/login";

export default function Login() {
  const [emailValue, setEmailValue] = useState("");
  const [passValue, setPassValue] = useState("");
  const [userFound, setUserFound] = useState("");

  const navigate = useNavigate();

  const submitForm = async () => {
    if (emailValue === "" || passValue === "") {
      setUserFound("Digite um email e senha");
      return;
    }

    const userData = await loginForm({
      email: emailValue,
      senha: passValue,
    });

    if (userData.message === "NOT_AUTHORIZED") {
      setUserFound("Senha incorreta");
    }

    if (userData.message === "NOT_FOUND") {
      setUserFound("Usuário não encontrado");
    }

    if (userData.message === "found") {
      setUserFound("");
      navigate("/staffprpetro");
      localStorage.setItem("authUser", Date.now());
      localStorage.setItem("staffname", userData?.data?.foundLogin?.nome);
      localStorage.setItem("staffmail", userData?.data?.foundLogin?.email);
      // props.saveUserGlobalData(userData);
      // localStorage.setItem("authUser", Date.now());
      // props.history.push("/events");
    }
  };
  return (
    <div className="account-pages my-5 pt-sm-5" style={{ paddingTop: 40 }}>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col className="col-7"></Col>
                  <Col className="col-5 align-self-end">
                    <img src={logo} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div className="auth-logo">
                  <Link to="/" className="auth-logo-dark">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={profile}
                          alt=""
                          className="rounded-circle"
                          height="50"
                        />
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="p-2">
                  <p style={{ color: "red" }}>{userFound}</p>
                  <div className="form-horizontal">
                    <div className="mb-3">
                      <Input
                        className="form-control"
                        placeholder="Insira seu email"
                        type="text"
                        required
                        onChange={(e) => setEmailValue(e.target.value)}
                        value={emailValue}
                      />
                    </div>

                    <div className="mb-3">
                      <Input
                        type="password"
                        required
                        placeholder="Insira sua senha"
                        onChange={(e) => setPassValue(e.target.value)}
                      />
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                        onClick={submitForm}
                      >
                        Entrar
                      </button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                © {new Date().getFullYear()} Inteegra Plus. Feito com{" "}
                <i className="mdi mdi-heart text-danger" /> pela Inteegra
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
