import React, { useEffect } from "react";

export default function Audio(props) {
  function stopOtherAudios() {
    if (props.play != props.function.function_id) {
      var audio = document.getElementById(props.function.function_id);
      audio.pause();
    }
  }

  useEffect(() => {
    stopOtherAudios();
  }, [props.play]);

  return (
    <div style={{ display: "flex", alignItems: "start" }}>
      {props.function.desbloqueado ? (
        <i
          className="bx bx-check-circle lock-audio"
          style={{
            color: "#00ff51",
            fontSize: 30,
            marginRight: 5,
          }}
        />
      ) : (
        <i
          className="bx bx-lock lock-audio"
          style={{
            color: "white",
            fontSize: 30,
            marginRight: 5,
          }}
        />
      )}
      <audio
        controls
        className="audio-danone"
        id={props.function.function_id}
        onPlay={() => {
          props.setPlay(props.function.function_id);
        }}
      >
        {props.function.desbloqueado && (
          <source src={props.audio} type="audio/mp3" />
        )}
      </audio>
    </div>
  );
}
