import React from 'react';
import { useNavigate } from 'react-router-dom';

import './navigate.css';

export default function Navigate() {
  const navigate = useNavigate()
  const handleClick = (e) => {
    e.preventDefault();
    if (e.target.value === '/loginsenac') {
      localStorage.removeItem('authUser');
      navigate(e.target.value);
    } else {
      navigate(e.target.value);
    }
  }

  return (
    <div className="tab" style={{display: 'flex', justifyContent: 'space-between'}}>
      <div>
        <button
          value="/staffsenac"
          className="tablinks"
          onClick={handleClick}
        >
          Ler QRCode
        </button>
        <button
          value="/reportstaff"
          className="tablinks"
          onClick={handleClick}
        >
          Relatório
        </button>
      </div>
      <div>
        <button
          value="/loginsenac"
          className="tablinks"
          onClick={handleClick}
        >
          Sair
        </button>
      </div>
    </div>
  )
}
