import React, { useState } from 'react';

import { Button, Card, CardBody, Col, Container, Label, Row } from 'reactstrap';

import Select from 'react-select';

import ModalPoints from './modalPoints';
import Navigate from '../../components/navigate';

const pointsOptions = [
  { value: 'add', label: 'Atribuir Pontos', isDisabled: true },
  { value: '5/add', label: 'Totem 1' },
  { value: '5/add', label: 'Totem 2' },
  { value: '5/add', label: 'Totem 3' },
  { value: '5/add', label: 'Totem 4' },
  { value: '5/add', label: 'Totem 5' },
  { value: '5/add', label: 'Totem 6' },
  { value: '5/add', label: 'Totem 7' },
  { value: '5/add', label: 'Totem 8' },
  { value: '5/add', label: 'Totem 9' },
  { value: '5/add', label: 'Totem 10' },
  { value: '5/add', label: 'Totem 11' },
  { value: '5/add', label: 'Totem 12' },
  { value: '10/add', label: 'Memorial do Comércio' },
  { value: '20/add', label: '10/07 - Abertura/Palestra 1' },
  { value: '15/add', label: '10/07 - HUB 1' },
  { value: '10/add', label: '10/07 - Palestra 2' },
  { value: '15/add', label: '10/07 - HUB 2' },
  { value: '15/add', label: '11/07 - HUB 3' },
  { value: '10/add', label: '11/07 - Palestra 3' },
  { value: '10/add', label: '11/07 - Palestra 4' },
  { value: '15/add', label: '11/07 - HUB 4' },
  { value: '10/add', label: '11/07 - Palestra 5' },
  { value: '10/add', label: '12/07 - Abertura/Palestra' },
  { value: '10/add', label: '12/07 - Palestras' },
  { value: '10/add', label: '12/07 - Espaço temático 14h' },
  { value: '10/add', label: '12/07 - Espaço temático 16h30' },
  { value: '10/add', label: '13/07 - Palestras 09h30' },
  { value: '10/add', label: '13/07 - Palestras 11h30' },
  { value: '10/add', label: '13/07 - Espaço temático 14h' },
  { value: '10/add', label: '13/07 - Espaço temático 16h30' },
  { value: '10/add', label: '14/07 - Palestras 09h30' },
  { value: '10/add', label: '14/07 - Palestras 11h30' },
  { value: '10/add', label: '14/07 - Espaço temático 14h' },
  { value: '10/add', label: '14/07 - Espaço temático 16h30' },
  { value: 'add', label: 'Trocar Pontos', isDisabled: true },
  { value: '10/remove', label: 'Brinde Hera' },
  { value: '10/remove', label: 'Brinde Ártemis' },
  { value: '20/remove', label: 'Brinde Apolo' },
  { value: '20/remove', label: 'Brinde Hermes' },
  { value: '40/remove', label: 'Brinde Atena' },
];

export default function Staff() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggle = () => {
    setModalIsOpen(false);
    setSelectedOption(null);
  }

  return (
    <div className="account-pages my-5 pt-sm-5" style={{ paddingTop: 40, marginLeft: '1vw' }}>
      {modalIsOpen && (
        <ModalPoints
          isOpen={modalIsOpen}
          toggle={toggle}
          selected={selectedOption}
        />
      )}
      <Container>
        <Row className="justify-content-center">
          <Col md={10} lg={8} xl={8}>
            <Navigate />
            <Card>
              <CardBody className="pt-0">
                <h1 style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 30,
                  fontWeight: 'bold',
                  marginTop: '15px',
                }}>Staff</h1>
                <hr />
                <div>
                  <Label htmlFor="edit-input-delet-options" className="form-label">
                    Selecione uma opção
                  </Label>
                  <Select
                    placeholder="Selecionar"
                    isMulti={false}
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e)}
                    options={pointsOptions}
                    id="formFile"
                  />
                </div>
                {selectedOption && (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                    style={{ marginTop: '15px' }}
                    onClick={() => setModalIsOpen(true)}
                  >
                    Confirmar
                  </Button>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
