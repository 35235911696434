import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import './styled.css';

import SweetAlert from 'react-bootstrap-sweetalert';
import { getFunctions, QRCodeApp } from '../../services/login';
import { createEarnedPoints } from '../../services/ranking';

const codeList = [
  { id: '788062e4-061e-4dd1-87aa-2fb78820f7a1', description: 'PLENARIAMANHA30/05' },
  { id: '1ed43156-6817-4ef8-bbe0-0cdd9e3d39a5', description: 'PLENARIATARDE30/05' },
  { id: '2161ee11-5085-43cf-8fdb-19accedb7fea', description: 'ONIBUSFRANCA-31/05' },
  { id: '25853800-7035-473d-aa48-ef24d88ea3b0', description: 'DINAMICADOHOTEL(AREAEXTERNA)-31/05' },
  { id: '69a8f57f-a77d-4b58-aa67-d645c312e2c4', description: 'PLENARIAMANHA01/06' },
  { id: 'cc4d5ff8-601c-4275-866d-4fee30c7c775', description: 'PLENARIATARDE01/06' },
  { id: '93f4635a-1ef1-49ab-aa0f-d7b63b16ac22', description: 'PLENARIAMANHA02/06' },
  { id: '79fe6daa-9f81-41ba-b8ab-78c698a4cfba', description: 'PLENARIATARDE02/06' },
  { id: '576c0651-9af1-4f01-83f2-43585c1f4ec0', description: 'PLENARIAMANHA02/06-NOVA' },
];

export default function QrcodeSomosLev() {
  const [user, setUser] = useState(null);
  const [scan, setScan] = useState(false);
  const [searchParams] = useSearchParams();
  //state de alerta
  const [qrcodeExisting, setQrcodeExisting] = useState(false);
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [qrcodeNotFound, setQrcodeNotFound] = useState(false);
  //state all qrcodes are read
  const [fullQrcode, setFullQrcode] = useState(false);

  async function scanQrcode(e) {
    const data = {
      participanteId: user,
      eventId: '014323e5-a987-483f-9521-a773a8657c2f',
      functionId: e,
      points: 10,
    };
    await QRCodeApp({ data })
      .then(async response => {
        if (response.message === 'FUNCTION_YET_RELEASED') {
          setQrcodeExisting(true);
        } else if (response.message === 'FUNCTION_RELEASED') {
          setQrcodeRead(true);
          const code = getCode(e);
          const dataRanking = {
            code: code.description,
            positive: true,
            log: 'add points senac',
          };
          await createEarnedPoints(dataRanking, data?.eventId, data?.participanteId).then(response => {
            if (response.message === 'ADDED_TO_QUEUE') {
              // console.log('QUEUED')
            } else {
              // console.log('queue error')
            }
          });
        } else setQrcodeNotFound(true);
      })
      .catch(error => console.log(error));
  }

  function getCode(id) {
    return codeList.filter(f => f.id === id)[0];
  }

  useEffect(() => {
    if (window.location.search !== '') {
      setUser(searchParams.get('idPart'));
    }
  }, [searchParams]);

  const getAllFunctions = useCallback(async () => {
    if (user) {
      await getFunctions(user, '014323e5-a987-483f-9521-a773a8657c2f')
        .then(response => {
          if (response.data.length > 0 && response.data.every(e => e.desbloqueado === true)) {
            setFullQrcode(true);
          }
        })
        .catch(error => console.log(error));
    }
  }, [user]);

  useEffect(() => {
    getAllFunctions();
  }, [getAllFunctions]);

  return (
    <div className="div-geral-qrcode-somoslev">
      {qrcodeExisting && (
        <SweetAlert
          warning
          title="Já foi usado"
          onConfirm={() => {
            setQrcodeExisting(false);
            setScan(false);
          }}>
          Esse QRCode já foi usado!
        </SweetAlert>
      )}
      {qrcodeRead && (
        <SweetAlert
          success
          title="Parabéns"
          onConfirm={() => {
            setQrcodeExisting(false);
            setQrcodeRead(false);
            setScan(false);
          }}>
          QRCode lido com suscesso
        </SweetAlert>
      )}
      {qrcodeNotFound && (
        <SweetAlert
          danger
          title="Não Encontrado"
          onConfirm={() => {
            setQrcodeNotFound(false);
            setScan(false);
          }}>
          QRCode não encontrado
        </SweetAlert>
      )}
      <div className="card-qrcode">
        {!fullQrcode ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}>
            {scan ? (
              <div className="div-qrcode">
                <QrReader
                  onScan={e => {
                    if (e) {
                      scanQrcode(e);
                    }
                  }}
                  style={{ width: '100%', heigth: '100px' }}
                />
                <i className="close-scan" onClick={() => setScan(false)}>
                  Fechar Scan
                </i>
              </div>
            ) : (
              <h1 className="reader-qrcode-somoslev" onClick={() => setScan(true) > getAllFunctions()}>
                Ler QRCode
              </h1>
            )}
          </div>
        ) : (
          <div>
            <h1 className="qrcode-msg">Parabéns, você já leu todos os QRCode's e ganhou todos os pontos!</h1>
          </div>
        )}
      </div>
    </div>
  );
}
