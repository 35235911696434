import React, { useState, useEffect } from "react";

import SweetAlert from "react-bootstrap-sweetalert";
import QrReader from "react-qr-reader";
import {
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
} from "reactstrap";

import { QRCodeStaff } from "../../services/login";
import {
  createEarnedPoints,
  getTotalPoints,
  verifyUser,
} from "../../services/ranking";

import { standsIds } from "./standsIds";

const eventId = "f39c77c9-9558-4be8-9ac4-fb405f171718";

export default function ModalPoints(props) {
  const [scan, setScan] = useState(false);
  //state de alerta
  const [qrcodeExisting, setQrcodeExisting] = useState(false);
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [qrcodeNotFound, setQrcodeNotFound] = useState(false);
  const [qrcodeNotPointsEnough, setQrcodeNotPointsEnough] = useState(false);
  const [nameStaff, setNameStaff] = useState("");
  const [emailStaff, setEmailStaff] = useState("");
  const [authScan, setAuthScan] = useState(false);
  const [closeWithAuth, setCloseWithAuth] = useState(false);
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [alertErro, setAlertErro] = useState("");

  const point = props.selected.value?.split("/")[0];
  const action = props.selected.value?.split("/")[1];

  useEffect(() => {
    setNameStaff(localStorage.getItem("staffname"));
    setEmailStaff(localStorage.getItem("staffmail"));
  }, []);

  function toggleBackdrop() {
    if (action === "remove") {
      props?.toggle();
    }
    if (authScan) {
      setAuthScan(false);
      setCloseWithAuth(true);
    }
    if (closeWithAuth && password === "inteegra@mice") {
      props?.toggle();
    }
  }
  async function scanQrcode(e) {
    if (
      qrcodeRead ||
      qrcodeNotFound ||
      qrcodeExisting ||
      qrcodeNotPointsEnough ||
      !e
    )
      return;
    setScan(false);
    const data = {
      pointingName: props.selected.label,
      eventId,
      qrcodeId: e,
      pointing: action === "add" ? +point : -point,
      nameStaff: nameStaff,
      emailStaff: emailStaff,
    };

    var verify = false;

    if (action === "remove") {
      const data = await getTotalPoints(eventId, e);

      if (data.totalPoints < point) {
        setQrcodeNotPointsEnough(true);
        return;
      } else {
        await verifyUser({
          eventId: eventId,
          userId: data.loginId,
        })
          .then((res) => {
            // verificar se é do grupo do paranapetro para poder escolher os dois primeiros itens da lista
            var search = res?.data?.grupos?.find(
              (e) => e === "10f4ac2c-107f-4d65-be61-7c6450a3b025"
            );

            if (
              !search &&
              (props.selected.label === "Copo ou Fone" ||
                props.selected.label === "Garrafa ou Carregador")
            ) {
              setAlertErro("Você não é associado Paranapetro");
              return;
            } else {
              // verificar se o usuário leu pelo menos 3 qrcodes da lista
              var total = 0;
              res?.data?.codes?.map((e) => {
                standsIds.map((code) => {
                  if (e === code) {
                    total += 1;
                  }
                });
              });

              if (total < 3) {
                setAlertErro(
                  "A jornada mínima de estandes do participante não foi concluída."
                );
                return;
              }
            }

            verify = true;
          })
          .catch((error) => {
            // console.log("erro", error);
          });
      }
    }

    // se atendeu aos requisitos pode prosseguir
    if (!verify) return;

    await QRCodeStaff({ data })
      .then(async (response) => {
        if (response.message === "POINTING_YET_RELEASED") {
          setQrcodeExisting(true);
        } else if (response.message === "POINTING_RELEASED") {
          setQrcodeRead(true);
          const dataRanking = {
            code: data.pointingName
              .toUpperCase()
              .replace(/\s/g, "")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
            positive: action === "add" ? true : false,
            log: action === "add" ? "add points staff" : "remove points staff",
          };
          await createEarnedPoints(dataRanking, eventId, e).then((response) => {
            if (response.message === "ADDED_TO_QUEUE") {
              // console.log('QUEUED')
            } else {
              // console.log('queue error')
            }
          });
        } else if (response.message.includes("duplicate key")) {
        } else setQrcodeNotFound(true);
      })
      .catch((error) => console.log(error));
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} backdrop="static">
      <ModalHeader toggle={!closeWithAuth ? () => toggleBackdrop() : ""}>
        {props.selected.label}
      </ModalHeader>

      <ModalBody>
        {!authScan && action === "add" ? (
          <div className="mb-3">
            <Input
              type="password"
              required
              placeholder="Insira a senha"
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorPassword("");
              }}
            />
            <p style={{ color: "red" }}>{errorPassword}</p>

            {!closeWithAuth ? (
              <Col md={12} lg={12} xl={12}>
                {/* <div className="mt-3 d-grid"> */}
                <Button
                  color="primary"
                  className="btn btn-primary btn-block waves-effect waves-light"
                  onClick={() => {
                    if (password === "inteegra@mice") {
                      if (!closeWithAuth) {
                        setAuthScan(true);
                      } else {
                        toggleBackdrop();
                      }
                      setPassword("");
                    } else {
                      setErrorPassword("Senha Incorreta");
                    }
                  }}
                >
                  {" "}
                  Liberar
                </Button>
                {/* </div> */}
                <Button
                  color="Success"
                  className="btn btn-success btn-block waves-effect waves-light mx-2"
                  onClick={() => {
                    props?.toggle();
                  }}
                >
                  {" "}
                  Voltar
                </Button>
              </Col>
            ) : (
              <div>
                <Col md={12} lg={12} xl={12}>
                  <Button
                    color="primary"
                    className="btn btn-primary btn-block waves-effect waves-light"
                    onClick={() => {
                      if (password === "inteegra@mice") {
                        if (!closeWithAuth) {
                          setAuthScan(true);
                        } else {
                          toggleBackdrop();
                        }
                        setPassword("");
                      } else {
                        setErrorPassword("Senha Incorreta");
                      }
                    }}
                  >
                    {" "}
                    Fechar
                  </Button>
                  <Button
                    color="Success"
                    className="btn btn-success btn-block waves-effect waves-light mx-2"
                    onClick={() => {
                      setAuthScan(true);
                      setCloseWithAuth(false);
                      setErrorPassword("");
                    }}
                  >
                    {" "}
                    Voltar Leitor
                  </Button>
                </Col>
              </div>
            )}
          </div>
        ) : (
          <div>
            {qrcodeExisting && (
              <SweetAlert
                warning
                title="Já foi usado"
                onConfirm={() => {
                  setQrcodeExisting(false);
                  setScan(false);
                }}
              >
                O participante já recebeu os pontos anteriormente.
              </SweetAlert>
            )}
            {qrcodeRead && (
              <SweetAlert
                success
                title={
                  action === "add" ? "Pontos atribuidos" : "Pontos trocados"
                }
                onConfirm={() => {
                  setQrcodeExisting(false);
                  setQrcodeRead(false);
                  setScan(false);
                }}
              >
                {action === "add"
                  ? `${point} pontos atribuídos ao participante!`
                  : `${point} pontos trocados pelo participante!`}
              </SweetAlert>
            )}

            {alertErro && (
              <SweetAlert
                error
                title={"Erro"}
                onConfirm={() => {
                  setAlertErro("");
                  setScan(false);
                }}
              >
                {alertErro}
              </SweetAlert>
            )}

            {qrcodeNotFound && (
              <SweetAlert
                danger
                title="Não Encontrado"
                onConfirm={() => {
                  setQrcodeNotFound(false);
                  setScan(false);
                }}
              >
                QRCode não encontrado
              </SweetAlert>
            )}
            {qrcodeNotPointsEnough && (
              <SweetAlert
                danger
                title="Pontos insuficientes"
                onConfirm={() => {
                  setQrcodeNotFound(false);
                  setScan(false);
                  setQrcodeNotPointsEnough(false);
                }}
              >
                O participante não possui pontos suficientes para realizar essa
                troca.
              </SweetAlert>
            )}
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h1 className="reader-qrcode" onClick={() => setScan(true)}>
                  Ler QRCode
                </h1>
                {scan && (
                  <div className="div-qrcode">
                    <QrReader
                      onScan={(e) => {
                        if (e) {
                          scanQrcode(e);
                        }
                      }}
                      style={{ width: "100%", heigth: "100px" }}
                    />
                    <i className="close-scan" onClick={() => setScan(false)}>
                      Fechar Scan
                    </i>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
