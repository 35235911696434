import writeXlsxFile from "write-excel-file";
import { getPoints } from "../../services/login";

export async function createXlsx({ idEvent, countData }) {
  const formatDate = (date) => {
    try {
      const formatedDate = date.split("T")[0].split("-").reverse().join("/");
      const formatTime = date.split("T")[1].split(".")[0];

      return `${formatedDate} ${formatTime}`;
    } catch (error) {
      return date;
    }
  };

  try {
    const schema = [
      {
        column: "ID do Evento",
        type: String,
        value: (points) => points.event?.id || "",
      },
      {
        column: "Nome do Evento",
        type: String,
        value: (points) => points.event?.title || "",
      },
      {
        column: "ID do QRCode",
        type: String,
        value: (points) => points.login?.id || "",
      },
      {
        column: "Nome do Participante",
        type: String,
        value: (points) => points.login?.nome || "",
      },
      {
        column: "Pontuação",
        type: Number,
        value: (points) => points.pointing,
      },
      {
        column: "Nome da Pontuação",
        type: String,
        value: (points) => points.pointingName,
      },
      {
        column: "Data e Hora",
        type: String,
        value: (points) => formatDate(points.createdAt),
      },
      {
        column: "Nome Staff",
        type: String,
        value: (points) => points?.nameStaff || "",
      },
      {
        column: "Email Staff",
        type: String,
        value: (points) => points?.emailStaff || "",
      },
    ];

    const limit = 50;

    const numberOfRequisitions = Math.ceil(countData / limit);
    const allRequests = [];

    for (let i = 1; i <= numberOfRequisitions; i += 1) {
      const response = await getPoints({
        idEvent,
        offset: i,
        limit,
      });

      allRequests.push(response.data);
      setTimeout(() => {}, 500);
    }

    const reports = allRequests.flat();

    await writeXlsxFile(reports, {
      schema,
      fileName: "pontos-participantes.xlsx",
    });
  } catch (error) {
    console.log(error);
  }
}
