export const standsIds = [
  "40-VRBENEFICIOS",
  "41-JFCONSULTORIA",
  "42-AUDENREFRIGERACAOLTDA",
  "43-EXCLUSIVEPISOSETAPETESPERSONALIZADOSLTDA",
  "44-VISOLUX",
  "45-PLURAL",
  "46-ENERGIADOBRASIL",
  "47-FAUKO",
  "48-PRATICA",
  "49-VIAFLEX",
  "50-RUBIX",
  "51-EXPOPOSTOS",
  "PARANAPETRO",
];
