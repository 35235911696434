import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import QrReader from "react-qr-reader";
import "./styled.css";

import SweetAlert from "react-bootstrap-sweetalert";
import { getFunctions, QRCodeApp } from "../../services/login";
import { createEarnedPoints } from "../../services/ranking";

const codeList = [
  { id: "b7ea01f8-6587-4144-b641-7954be430501", description: "1-RODOIL" },
  { id: "7c745c89-6dc2-48f6-a0ff-6c6a6c2e0412", description: "2-GOTTI" },
  {
    id: "314f901f-8ea3-40b9-99b8-1bdaaf7a3747",
    description: "3-SIMDISTRIBUIDORA/DISTRIBUIDORACHARRUA",
  },
  { id: "6e35bfb7-7b79-4334-8577-6b8831152066", description: "4-RDP" },
  { id: "5b8ddfa4-2a67-4303-b78c-d61f37d88137", description: "5-BANCOSENFF" },
  { id: "5a3021cd-0f00-487d-9566-249e479a2cb6", description: "6-CIAPETRO" },
  { id: "2969487f-09e5-479d-877e-aba559d159d9", description: "7-LOTHUS" },
  { id: "1ca381ea-51ae-4696-b3f8-8a03b19ff88f", description: "8-XPERT" },
  {
    id: "812ebc89-fb5b-4e5c-ac9c-17c796171ca5",
    description: "9-GASMED/ECOBRASIL/ZEPPINI",
  },
  {
    id: "727b3b7e-1ec8-4cf1-96f2-e13658b1aa6f",
    description: "10-VINCENTINSEGUROS",
  },
  { id: "ce879bfd-0dd0-4498-9ae2-f66b12df6af6", description: "11-BRIXBOX" },
  { id: "4f1e0327-0545-4538-b5c7-2066194109bb", description: "12-IPIRANGA" },
  { id: "393bc715-4a89-48f9-881f-e9f37228f80f", description: "13-RAIZEN" },
  { id: "6773992a-5623-4494-ae86-27cac62a92e4", description: "14-VIBRA" },
  {
    id: "1b6a5c3d-c38f-43e9-88bd-e9b60e34da0b",
    description: "15-INSTITUTOCOMBUSTIVELLEGAL",
  },
  { id: "9451baab-97e5-430f-8e77-4ebd3fa0b4b6", description: "16-BIOPRESERVE" },
  {
    id: "a5517c22-57e6-498b-b353-4f81cadc95d7",
    description: "17-PLUMASCONTABIL",
  },
  { id: "ad2c21cc-3598-4fd7-8042-783223ba9b64", description: "18-LBCSISTEMAS" },
  {
    id: "f0370856-f6bf-4550-907a-32385a3b9952",
    description: "19-EXCELBR/SULFILTROS",
  },
  { id: "690f00d8-accf-4b78-b980-7b7914ef110b", description: "20-TABORDA" },
  { id: "02b152f4-14d6-469f-9c20-f49b77e50bff", description: "21-SAFE7" },
  { id: "077ccf8d-c0fe-48be-9057-3c345d0d96c8", description: "22-ATEM" },
  { id: "aef3f51a-196b-49bc-9174-f9f36c2677f3", description: "23-LABGEO" },
  { id: "85b0e0c1-aab7-4924-92dc-a8d8d1e7aec4", description: "24-CLUBPETRO" },
  { id: "b3eca44e-a302-4c5e-b6be-76c5ef5c127a", description: "25-LEDWAVE" },
  { id: "45729bd3-7d86-44a2-bca5-60736707b017", description: "26-PROTEGE" },
  { id: "1f13253f-8375-4f31-9e7f-2b5e90c7a0a7", description: "27-LINX" },
  {
    id: "571c04e5-94d3-46d3-aaf4-86fe63c4697b",
    description: "28-CYMCOALIMENTOS",
  },
  {
    id: "19712529-86a1-4422-b4d6-b66fd3530624",
    description: "29-UNIDISTRIBUIDORA",
  },
  { id: "aebc8146-01f3-4a52-82ab-b904b61e1670", description: "30-WEBPOSTO" },
  {
    id: "62a02662-b521-4244-ab7c-2f11d549eb18",
    description: "31-COMPANYTECAUTOMACAO",
  },
  { id: "f47f3750-ed82-44f9-af00-e29a77188977", description: "32-WERTCO" },
  { id: "781a6508-251a-4c4e-b93a-954530156fb7", description: "33-LUPUS" },
  { id: "8385051f-b588-4247-a3af-fbd96317b67d", description: "34-BRINKS" },
  {
    id: "260ed3a0-2e26-4e2a-b100-e0e418a69fc9",
    description: "35-SUPERAMBIENTAL",
  },
  {
    id: "7870d3f6-40f4-4377-8359-528c6ef65abb",
    description: "36-CHACARAPINHEIRO",
  },
  { id: "17457051-bc4b-4134-a2ae-6c0f6cf96f3e", description: "37-ALE" },
  {
    id: "91bcd2f0-6b8e-4202-a119-f7619f6e8fa3",
    description: "38-IMPACTOGEOLOGIA",
  },
  { id: "94a375b7-73d5-4040-8e32-6be4061bc090", description: "39-PROMATEC" },
  {
    id: "97d81479-1f2a-42d1-91e7-ab5e52b38079",
    description: "40-VRBENEFICIOS",
  },
  {
    id: "4104b304-d034-4ba1-a66f-23fc50e40907",
    description: "41-JFCONSULTORIA",
  },
  {
    id: "d7f144ea-1de7-4680-8e60-33d9ac6e5d95",
    description: "42-AUDENREFRIGERACAOLTDA",
  },
  {
    id: "0e37baee-3944-4976-baf9-29ea94842f3b",
    description: "43-EXCLUSIVEPISOSETAPETESPERSONALIZADOSLTDA",
  },
  { id: "1d718cf8-ff68-41d8-97fc-229bbee5d837", description: "44-VISOLUX" },
  { id: "bd03b496-b025-44c3-a70f-508bd1487c0d", description: "45-PLURAL" },
  {
    id: "c51fbe73-af55-4d0c-b452-9c467ec2c9a5",
    description: "46-ENERGIADOBRASIL",
  },
  { id: "9ca9ad84-d708-46c8-bec8-3aa222a4ba50", description: "47-FAUKO" },
  { id: "c22564cf-5cd0-440b-9392-a9d9edb3d0ce", description: "48-PRATICA" },
  { id: "5e753061-06b7-4082-b20a-135703c2b4d9", description: "49-VIAFLEX" },
  { id: "4e4da5fb-3436-4ab8-881f-7d25c82540e5", description: "50-RUBIX" },
  { id: "d79036b6-b982-4c7d-82c2-a887b9103404", description: "51-EXPOPOSTOS" },
  { id: "a33efe0d-51cc-4205-82f5-c095a0ac7d07", description: "PARANAPETRO" },
];

export default function QrcodeParanaPetro() {
  const [user, setUser] = useState(null);
  const [scan, setScan] = useState(false);
  const [searchParams] = useSearchParams();
  //state de alerta
  const [qrcodeExisting, setQrcodeExisting] = useState(false);
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [qrcodeNotFound, setQrcodeNotFound] = useState(false);
  //state all qrcodes are read
  const [fullQrcode, setFullQrcode] = useState(false);

  async function scanQrcode(e) {
    const data = {
      participanteId: user,
      eventId: "f39c77c9-9558-4be8-9ac4-fb405f171718",
      functionId: e,
      points: 10,
    };
    await QRCodeApp({ data })
      .then(async (response) => {
        if (response.message === "FUNCTION_YET_RELEASED") {
          setQrcodeExisting(true);
        } else if (response.message === "FUNCTION_RELEASED") {
          setQrcodeRead(true);
          const code = getCode(e);
          const dataRanking = {
            code: code.description,
            positive: true,
            log: "add points senac",
          };
          await createEarnedPoints(
            dataRanking,
            data?.eventId,
            data?.participanteId
          ).then((response) => {
            if (response.message === "ADDED_TO_QUEUE") {
              // console.log('QUEUED')
            } else {
              // console.log('queue error')
            }
          });
        } else setQrcodeNotFound(true);
      })
      .catch((error) => console.log(error));
  }

  function getCode(id) {
    return codeList.filter((f) => f.id === id)[0];
  }

  useEffect(() => {
    if (window.location.search !== "") {
      setUser(searchParams.get("idPart"));
    }
  }, [searchParams]);

  const getAllFunctions = useCallback(async () => {
    if (user) {
      console.log("user", user);
      await getFunctions(user, "f39c77c9-9558-4be8-9ac4-fb405f171718")
        .then((response) => {
          console.log("ok", response);
          if (
            response.data.length > 0 &&
            response.data.every((e) => e.desbloqueado === true)
          ) {
            setFullQrcode(true);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  useEffect(() => {
    getAllFunctions();
  }, [getAllFunctions]);

  return (
    <div className="div-geral-qrcode" style={{ backgroundColor: "#f3c300" }}>
      {qrcodeExisting && (
        <SweetAlert
          warning
          title="Já foi usado"
          onConfirm={() => {
            setQrcodeExisting(false);
            setScan(false);
          }}
        >
          Esse QRCode já foi usado!
        </SweetAlert>
      )}
      {qrcodeRead && (
        <SweetAlert
          success
          title="Parabéns"
          onConfirm={() => {
            setQrcodeExisting(false);
            setQrcodeRead(false);
            setScan(false);
          }}
        >
          Você ganhou mais 10 pontos!
        </SweetAlert>
      )}
      {qrcodeNotFound && (
        <SweetAlert
          danger
          title="Não Encontrado"
          onConfirm={() => {
            setQrcodeNotFound(false);
            setScan(false);
          }}
        >
          QRCode não encontrado
        </SweetAlert>
      )}
      <div className="card-qrcode">
        {!fullQrcode ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {scan ? (
              <div className="div-qrcode">
                <QrReader
                  onScan={(e) => {
                    if (e) {
                      scanQrcode(e);
                    }
                  }}
                  style={{ width: "100%", heigth: "100px" }}
                />
                <i className="close-scan" onClick={() => setScan(false)}>
                  Fechar Scan
                </i>
              </div>
            ) : (
              <h1
                className="reader-qrcode-senac"
                style={{ backgroundColor: "#3e3681" }}
                onClick={() => setScan(true) > getAllFunctions()}
              >
                Ler QRCode
              </h1>
            )}
          </div>
        ) : (
          <div>
            <h1 className="qrcode-msg">
              Parabéns, você já leu todos os QRCode's e ganhou todos os pontos!
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}
