import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// componentes
import ReadQrcode from "./readQrcode";

export default function Qrcode() {
  // parametros
  const [searchParams] = useSearchParams();
  // usuario
  const [user, setUser] = useState(null);
  // carregamento
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.location.search !== "") {
      const idPart = searchParams.get("idPart");
      const idEvent = searchParams.get("idEvent");
      setUser({
        idPart,
        idEvent,
      });
    }

    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [searchParams]);

  if (loading)
    return (
      <div
        className="div-general-qrcode"
        style={{ backgroundColor: "#1b63f9" }}
      >
        <a className="a-loading" />
      </div>
    );
  else
    return (
      <div
        className="div-general-qrcode"
        style={{ backgroundColor: "#1b63f9" }}
      >
        <ReadQrcode />
      </div>
    );
}
