import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import './styled.css';

import SweetAlert from 'react-bootstrap-sweetalert';
import { getFunctions, QRCodeApp } from '../../services/login';
import { createEarnedPoints } from '../../services/ranking';

const codeList = [
  { id: 'c6fdeeda-35a1-4b1c-a886-1c00cf4b0d0e', description: 'ESTANDE1' },
  { id: '4cbe3048-a882-4443-b1f2-1cdbf184aef8', description: 'ESTANDE2' },
  { id: 'ed3e9729-0adf-46b9-a55e-b98ed7d8f21d', description: 'ESTANDE3' },
  { id: '22523038-41a6-4f4d-bc5a-917dbd47a7f5', description: 'ESTANDE4' },
  { id: '1d2a8889-e97f-4ada-b9b8-1cd2357467a8', description: 'ESTANDE5' },
  { id: '68b7d502-6244-42b5-a9f3-ae8a6d33ad61', description: 'ESTANDE6' },
  { id: 'c88e4c43-3bd3-41e2-b5f8-f4b12cddad2e', description: 'ESTANDE7' },
  { id: '7b7e9ed1-1b9d-4d2e-8fb1-90d9b1726832', description: 'ESTANDE8' },
  { id: 'f120a25e-0c3f-4b47-895f-a6530ec734e3', description: 'ESTANDE9' },
  { id: 'ce6bc30c-0e8b-4d08-b855-97a20a310323', description: 'ESTANDE10' },
  { id: '42f8b2e6-b4ee-4c91-9ada-433b78c5b007', description: 'ESTANDE11' },
  { id: 'ee4900b7-3d1e-4837-b364-280497793c44', description: 'ESTANDE12' },
  { id: 'e28b177b-5ce8-4c8b-9536-3baeb0047b02', description: 'ESTANDE13' },
  { id: '315baa78-d41d-41ca-a7cd-e32b6e290fbb', description: 'ESTANDE14' },
  { id: 'cade2a70-40ce-488d-b7e8-fcfb6ce79e6d', description: 'ESTANDE15' },
  { id: '00ae48a9-c4a0-4d08-903e-96939ccf3291', description: 'ESTANDE16' },
  { id: 'de6d48b9-0e70-4347-a961-7962d0b5e50d', description: 'ESTANDE17' },
  { id: '0986347e-fb88-4c83-ad07-51795659f89c', description: 'ESTANDE18' },
  { id: '1ef0bc6c-ca91-4039-a157-efc823a9560d', description: 'ESTANDE19' },
  { id: '15a02ee0-4390-41be-a5c0-234268b56740', description: 'ESTANDE20' },
  { id: '144192a6-49f9-4e61-9ca5-456275ba9982', description: 'ESTANDE21' },
  { id: '2e9d4f52-88fa-4ed9-a2be-5773104cf57f', description: 'ESTANDE22' },
  { id: 'f253337b-1a9e-4420-b723-df098bdf177c', description: 'ESTANDE23' },
  { id: 'fbcd8725-a0ed-44fa-aa77-35e0d9a4764a', description: 'ESTANDE24' },
  { id: '42979fa4-8d7e-45ef-bbbf-2dc33c3beb37', description: 'ESTANDE25' },
  { id: '6a0fa42b-a1f2-416a-93e4-b11425601901', description: 'ESTANDE26' },
];

export default function QrcodeSenac() {
  const [user, setUser] = useState(null);
  const [scan, setScan] = useState(false);
  const [searchParams] = useSearchParams();
  //state de alerta
  const [qrcodeExisting, setQrcodeExisting] = useState(false);
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [qrcodeNotFound, setQrcodeNotFound] = useState(false);
  //state all qrcodes are read
  const [fullQrcode, setFullQrcode] = useState(false);

  async function scanQrcode(e) {
    const data = {
      participanteId: user,
      eventId: 'b18d3a51-aa8b-442f-be8c-6f55f6737609',
      functionId: e,
      points: 10,
    };
    await QRCodeApp({ data })
      .then(async response => {
        if (response.message === 'FUNCTION_YET_RELEASED') {
          setQrcodeExisting(true);
        } else if (response.message === 'FUNCTION_RELEASED') {
          setQrcodeRead(true);
          const code = getCode(e);
          const dataRanking = {
            code: code.description,
            positive: true,
            log: 'add points senac',
          };
          await createEarnedPoints(dataRanking, data?.eventId, data?.participanteId).then(response => {
            if (response.message === 'ADDED_TO_QUEUE') {
              // console.log('QUEUED')
            } else {
              // console.log('queue error')
            }
          });
        } else setQrcodeNotFound(true);
      })
      .catch(error => console.log(error));
  }

  function getCode(id) {
    return codeList.filter(f => f.id === id)[0];
  }

  useEffect(() => {
    if (window.location.search !== '') {
      setUser(searchParams.get('idPart'));
    }
  }, [searchParams]);

  const getAllFunctions = useCallback(async () => {
    if (user) {
      await getFunctions(user, 'b18d3a51-aa8b-442f-be8c-6f55f6737609')
        .then(response => {
          if (response.data.length > 0 && response.data.every(e => e.desbloqueado === true)) {
            setFullQrcode(true);
          }
        })
        .catch(error => console.log(error));
    }
  }, [user]);

  useEffect(() => {
    getAllFunctions();
  }, [getAllFunctions]);

  return (
    <div className="div-geral-qrcode" style={{ backgroundColor: '#101f3a' }}>
      {qrcodeExisting && (
        <SweetAlert
          warning
          title="Já foi usado"
          onConfirm={() => {
            setQrcodeExisting(false);
            setScan(false);
          }}>
          Esse QRCode já foi usado!
        </SweetAlert>
      )}
      {qrcodeRead && (
        <SweetAlert
          success
          title="Parabéns"
          onConfirm={() => {
            setQrcodeExisting(false);
            setQrcodeRead(false);
            setScan(false);
          }}>
          Você ganhou mais 10 pontos!
        </SweetAlert>
      )}
      {qrcodeNotFound && (
        <SweetAlert
          danger
          title="Não Encontrado"
          onConfirm={() => {
            setQrcodeNotFound(false);
            setScan(false);
          }}>
          QRCode não encontrado
        </SweetAlert>
      )}
      <div className="card-qrcode">
        {!fullQrcode ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}>
            {scan ? (
              <div className="div-qrcode">
                <QrReader
                  onScan={e => {
                    if (e) {
                      scanQrcode(e);
                    }
                  }}
                  style={{ width: '100%', heigth: '100px' }}
                />
                <i className="close-scan" onClick={() => setScan(false)}>
                  Fechar Scan
                </i>
              </div>
            ) : (
              <h1
                className="reader-qrcode-senac"
                style={{ backgroundColor: '#002465' }}
                onClick={() => setScan(true) > getAllFunctions()}>
                Ler QRCode
              </h1>
            )}
          </div>
        ) : (
          <div>
            <h1 className="qrcode-msg">Parabéns, você já leu todos os QRCode's e ganhou todos os pontos!</h1>
          </div>
        )}
      </div>
    </div>
  );
}
