export const codeList = [
  { description: "IMAGEMAXEOS", id: "967862f6-9e6b-4a92-8096-f9dc64b481b6" },
  {
    description: "PRIMESCANPRIMEMILL",
    id: "8586a361-3244-449d-abff-1937b08f05dc",
  },
  {
    description: "PRIMEPRINT",
    id: "57b19424-730f-48db-ab1f-2c9e3e5520b7",
  },
  {
    description: "SOLUCOESENDODONTIA",
    id: "c831c918-0acd-4fbd-aeef-74cfac835d40",
  },
  {
    description: "ALINHADORESSURESMILE",
    id: "03252516-2e50-467b-998e-8f544f9b51a4",
  },
  // {
  //   description: "EXTRA1",
  //   id: "a876b87a-f3d4-495a-b99e-452f11ede00c",
  // },
  // {
  //   description: "EXTRA2",
  //   id: "c8b8bdfc-da6f-4709-83e8-dda6c7251afe",
  // },
  // {
  //   description: "EXTRA3",
  //   id: "d7ccd174-7d02-4715-a564-3ffa5b8f8595",
  // },
  // {
  //   description: "EXTRA4",
  //   id: "26a8d7de-c41c-4391-a426-c28766305759",
  // },
  // {
  //   description: "EXTRA5",
  //   id: "d7edae39-0dfa-4315-ba28-bc1a8141cfe2",
  // },
];
