import React, { useState } from "react";

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";

import Select from "react-select";

import ModalPoints from "./modalPoints";
import Navigate from "../../components/petronavigate";

const pointsOptions = [
  { value: "add", label: "Trocar Pontos", isDisabled: true },
  { value: "220/remove", label: "Copo ou Fone" },
  { value: "150/remove", label: "Garrafa ou Carregador" },
  { value: "150/remove", label: "Mochila ou Hub" },
  { value: "120/remove", label: "Ring light ou Jogo caneta marca texto" },
  { value: "100/remove", label: "Cartão bloqueador RFID ou Caneta" },
];

export default function Staff() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggle = () => {
    setModalIsOpen(false);
    setSelectedOption(null);
  };

  return (
    <div
      className="account-pages my-5 pt-sm-5"
      style={{ paddingTop: 40, marginLeft: "1vw" }}
    >
      {modalIsOpen && (
        <ModalPoints
          isOpen={modalIsOpen}
          toggle={toggle}
          selected={selectedOption}
        />
      )}
      <Container>
        <Row className="justify-content-center">
          <Col md={10} lg={8} xl={8}>
            <Navigate />
            <Card>
              <CardBody className="pt-0">
                <h1
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 30,
                    fontWeight: "bold",
                    marginTop: "15px",
                  }}
                >
                  Staff
                </h1>
                <hr />
                <div>
                  <Label
                    htmlFor="edit-input-delet-options"
                    className="form-label"
                  >
                    Selecione uma opção
                  </Label>
                  <Select
                    placeholder="Selecionar"
                    isMulti={false}
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e)}
                    options={pointsOptions}
                    id="formFile"
                  />
                </div>
                {selectedOption && (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                    style={{ marginTop: "15px" }}
                    onClick={() => setModalIsOpen(true)}
                  >
                    Confirmar
                  </Button>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
