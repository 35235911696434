import React, { useCallback, useEffect, useState } from "react";

import { Button, Card, CardBody, Input, Table } from "reactstrap";

import "../../style/reports.css";

import { createXlsx } from "./createXlsx";
import Pagination from "../../components/pagination";
import { getPoints } from "../../services/login";
import Navigate from "../../components/petronavigate";

const idEvent = 'f39c77c9-9558-4be8-9ac4-fb405f171718';

export default function ReportParticipants() {
  const [points, setPoints] = useState([]);
  const [search, setSearch] = useState("");

  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const formatDate = (date) => {
    try {
      const formatedDate = date.split("T")[0].split("-").reverse().join("/");
      const formatTime = date.split("T")[1].split(".")[0];

      return `${formatedDate} ${formatTime}`;
    } catch (error) {
      return date;
    }
  };

  const allPoints = useCallback(async () => {
    const response = await getPoints({
      search,
      idEvent,
      offset: currentPage,
      limit: itemsPerPage,
    });
    setPoints(response.data || []);
    setTotalItems(response.total);
  }, [search, currentPage, itemsPerPage]);

  useEffect(() => {
    allPoints();
  }, [allPoints]);

  return (
    <div className="report-container">
      <Card className="card-container">
        <Navigate />
        <CardBody>
          <div className="card-title">
            <h5>Relatório de pontos</h5>
          </div>
          <div className="export-and-search-container">
            <Input
              className="search-input"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value) > setCurrentPage(1)}
            />
            <Button
              color="success"
              onClick={() => createXlsx({ idEvent, countData: totalItems })}
            >
              Exportar Relatório
            </Button>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID do QRCode</th>
                  <th>Nome do Participante</th>
                  <th>Pontuação</th>
                  <th>Nome da Pontuação</th>
                  <th>Data e Hora</th>
                </tr>
              </thead>
              <tbody>
                {points.length > 0 &&
                  points.map((point, index) => (
                    <tr key={index}>
                      <td>{point.login?.id}</td>
                      <td>{point.login?.nome}</td>
                      <td>{point.pointing}</td>
                      <td>{point.pointingName}</td>
                      <td>{formatDate(point.createdAt)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div
        style={{ display: "flex", alignSelf: "flex-end", marginRight: "5%" }}
      >
        <Pagination
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          totalItems={totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}
