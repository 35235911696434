import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import QrReader from "react-qr-reader";
import "./styled.css";

import SweetAlert from "react-bootstrap-sweetalert";
import { getFunctions, QRCodeApp } from "../../services/login";
import { createEarnedPoints } from "../../services/ranking";
import { codeList } from "./codeList";

export default function QrcodeDSWorld() {
  const [user, setUser] = useState(null);
  const [scan, setScan] = useState(false);
  const [searchParams] = useSearchParams();
  //state de alerta
  const [qrcodeExisting, setQrcodeExisting] = useState(false);
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [qrcodeNotFound, setQrcodeNotFound] = useState(false);
  //state all qrcodes are read
  const [fullQrcode, setFullQrcode] = useState(false);
  // id do evento
  const idEvent = "bd773356-cb9d-4d74-8e0d-3ca7bc59f5b3";

  async function scanQrcode(e) {
    setScan(false);

    const data = {
      participanteId: user,
      eventId: idEvent,
      functionId: e,
      points: 10,
    };

    await QRCodeApp({ data })
      .then(async (response) => {
        if (response.message === "FUNCTION_YET_RELEASED") {
          setQrcodeExisting(true);
        } else if (response.message === "FUNCTION_RELEASED") {
          setQrcodeRead(true);
          const code = getCode(e);
          const dataRanking = {
            code: code.description,
            positive: true,
            log: "add points senac",
          };
          await createEarnedPoints(
            dataRanking,
            data?.eventId,
            data?.participanteId
          ).then((response) => {
            if (response.message === "ADDED_TO_QUEUE") {
              // console.log('QUEUED')
            } else {
              // console.log('queue error')
            }
          });
        } else setQrcodeNotFound(true);
      })
      .catch((error) => console.log(error));
  }

  function getCode(id) {
    return codeList.filter((f) => f.id === id)[0];
  }

  useEffect(() => {
    if (window.location.search !== "") {
      setUser(searchParams.get("idPart"));
    }
  }, [searchParams]);

  const getAllFunctions = useCallback(async () => {
    if (user) {
      await getFunctions(user, idEvent)
        .then((response) => {
          if (
            response.data.length > 0 &&
            response.data.every((e) => e.desbloqueado === true)
          ) {
            setFullQrcode(true);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  useEffect(() => {
    getAllFunctions();
  }, [getAllFunctions]);

  return (
    <div className="div-geral-qrcode" style={{ backgroundColor: "#1b63f9" }}>
      {qrcodeExisting && (
        <SweetAlert
          warning
          title="Já foi usado"
          onConfirm={() => {
            setQrcodeExisting(false);
            setScan(false);
          }}
        >
          Esse QRCode já foi usado!
        </SweetAlert>
      )}
      {qrcodeRead && (
        <SweetAlert
          success
          title="Parabéns"
          onConfirm={() => {
            setQrcodeExisting(false);
            setQrcodeRead(false);
            setScan(false);
          }}
        >
          Você ganhou mais 100 pontos!
        </SweetAlert>
      )}
      {qrcodeNotFound && (
        <SweetAlert
          danger
          title="Não Encontrado"
          onConfirm={() => {
            setQrcodeNotFound(false);
            setScan(false);
          }}
        >
          QRCode não encontrado
        </SweetAlert>
      )}
      <div className="card-qrcode" style={{ padding: 0, border: "none" }}>
        {!fullQrcode ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              backgroundColor: "white",
              padding: 30,
              borderRadius: 10,
            }}
          >
            {scan ? (
              <div className="div-qrcode">
                <QrReader
                  onScan={(e) => {
                    if (e) {
                      scanQrcode(e);
                    }
                  }}
                  style={{ width: "100%", heigth: "100px" }}
                />
                <i
                  className="close-scan"
                  style={{ marginTop: 20 }}
                  onClick={() => setScan(false)}
                >
                  Fechar Scan
                </i>
              </div>
            ) : (
              <h1
                className="reader-qrcode-senac"
                style={{ backgroundColor: "#3e3681", marginBottom: 0 }}
                onClick={() => setScan(true) > getAllFunctions()}
              >
                Ler QRCode
              </h1>
            )}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "white",
              padding: 20,
              textAlign: "center",
            }}
          >
            <h1
              className="qrcode-msg"
              style={{
                color: "black",
                textAlign: "center",
              }}
            >
              Parabéns, você já leu todos os QRCode's e ganhou todos os pontos!
            </h1>
            <h3>
              Retire seu brinde ao lado do Globo DS WORLD no nosso show Room.
            </h3>
          </div>
        )}
      </div>
    </div>
  );
}
