export const codeList = [
  { description: "CIRCUITO1-9H30", id: "2aa70df2-a7d5-4aec-976a-c8e7e7fa7916" },
  { description: "CIRCUITO1-9H55", id: "6391e5cf-5bd2-48d7-8a63-5c517dc409bf" },
  {
    description: "CIRCUITO1-10H20",
    id: "63adffd1-e6a1-42be-bc88-3fc318054e5f",
  },
  {
    description: "CIRCUITO1-10H45",
    id: "20643384-4846-4302-bc97-abb9e87ccd77",
  },
  {
    description: "CIRCUITO1-11H10",
    id: "5c04d2e7-94f6-4403-bbcd-f44be5641322",
  },
  {
    description: "CIRCUITO1-11H35",
    id: "43bee81d-af19-414a-8e64-0d592af2dbee",
  },
  {
    description: "CIRCUITO1-02H40",
    id: "85276e8b-d1be-4c5a-abbc-046cf0e14d59",
  },
  {
    description: "CIRCUITO1-03H05",
    id: "0e2707a7-7ab7-48cb-897b-81de64be2072",
  },
  {
    description: "CIRCUITO1-03H30",
    id: "be3e4906-76e8-4c2b-b7bc-a730b109eb6b",
  },
  {
    description: "CIRCUITO1-03H55",
    id: "339dee94-3e22-432f-aa23-bf37e013fd8d",
  },
  {
    description: "CIRCUITO1-04H20",
    id: "411dec7e-e5f9-446d-91bf-d69f0b72e22d",
  },
  {
    description: "CIRCUITO1-04H45",
    id: "105d7de2-2904-4101-88ac-694ff315449d",
  },
  { description: "CIRCUITO2-9H30", id: "e93a231e-3b6d-451b-b954-652efa2dce3a" },
  { description: "CIRCUITO2-9H55", id: "ca733abe-c5db-48c2-804a-cb7e54b0770a" },
  {
    description: "CIRCUITO2-10H20",
    id: "5db18523-8fc8-4a22-b01a-f37849f0fb65",
  },
  {
    description: "CIRCUITO2-10H45",
    id: "7e4a3b3b-7f37-41ce-817a-b3aa59960a7c",
  },
  {
    description: "CIRCUITO2-11H10",
    id: "b293ad89-9d5f-41f3-82db-2659b8353000",
  },
  {
    description: "CIRCUITO2-11H35",
    id: "c9b68f85-bdce-452f-ad1c-24b4f623aaa5",
  },
  {
    description: "CIRCUITO2-02H40",
    id: "7d78e820-3e1e-4ef7-89b5-083caa71a779",
  },
  {
    description: "CIRCUITO2-03H05",
    id: "f047fb91-246b-484b-8117-df3470dc0974",
  },
  {
    description: "CIRCUITO2-03H55",
    id: "d17fd106-826b-418d-9954-e423acff4ff4",
  },
  {
    description: "CIRCUITO2-04H20",
    id: "0608565c-8226-4bb6-a373-ff4e75e592d5",
  },
  {
    description: "CIRCUITO2-04H45",
    id: "a9f0cb3b-2c8d-4e5f-9806-98b2a2f6bad4",
  },
  {
    description: "CIRCUITO2-03H30",
    id: "71752fc7-2fd4-4411-95ad-33347b925e9d",
  },
  { description: "WOW-9H30", id: "c772567e-34d9-46a5-87a2-724ca7315fa4" },
  { description: "WOW9H55", id: "42e1a1a7-e0e1-406d-bff3-1f513f7e00e4" },
  { description: "WOW10H20", id: "8b80e23b-ab59-4605-91eb-00d5d835699e" },
  { description: "WOW10H45", id: "e37863fc-8b38-494d-97e6-26c552855123" },
  { description: "WOW11H10", id: "0443826b-c5fe-4821-9d22-484ac4d07194" },
  { description: "WOW11H35", id: "e60f3d0a-b235-444b-9e2f-8f041e07748a" },
  { description: "WOW02H40", id: "cc86a8de-b99f-4192-ae3c-35a607017c14" },
  { description: "WOW03H05", id: "f3dd8b01-0355-43ae-a720-4c97477a473c" },
  { description: "WOW03H30", id: "1a8151d9-c139-4c88-bf04-f7915e58f04a" },
  { description: "WOW03H55", id: "2ce17905-36cb-4096-a2ad-99bec92b4fdd" },
  { description: "WOW04H20", id: "d034b54a-3be8-4e76-822c-15578d5aa124" },
  { description: "WOW04H45", id: "fefaf9b4-aac5-4205-a59d-45b2c59195da" },
  { description: "AFRICA-9H30", id: "5d84c83c-8192-49e8-8f21-6ad7bb2c6f3f" },
  { description: "AFRICA-9H55", id: "f7df56d9-e337-4e52-8f22-c9ab484a759c" },
  { description: "AFRICA-10H20", id: "f0d65ae7-cc69-41da-b124-5f7fa6108e3b" },
  { description: "AFRICA-10H45", id: "3a1ebb0e-285e-47a0-8c5e-ca5df931cb48" },
  { description: "AFRICA-11H10", id: "0e1c4ada-470f-40b8-956c-70942ffc902e" },
  { description: "AFRICA-11H35", id: "55f71529-07af-4637-bed2-72da380238b1" },
  { description: "AFRICA-02H40", id: "bba53fe0-8a2e-41b7-a0af-c4252bcf0f81" },
  { description: "AFRICA-03H05", id: "8cefe521-3f0d-4bdb-abcf-066132164227" },
  { description: "AFRICA-03H30", id: "0d29aff5-9b55-401f-bf10-7969be49d1b3" },
  { description: "AFRICA-03H55", id: "5b277f86-f1c8-4187-9382-19e6b919ec07" },
  { description: "AFRICA-04H20", id: "a0f640d4-1ef4-4add-8dfd-735067460961" },
  { description: "AFRICA-04H45", id: "189374d4-11f8-4c16-844e-c50446612680" },
  { description: "WHAT-9H30", id: "4f47a8ab-61b2-4b78-9773-2a2c715c4ffd" },
  { description: "WHAT-9H55", id: "cc70abec-f866-46f9-84b7-d1a7e0c35575" },
  { description: "WHAT-10H20", id: "1d790f44-ebe6-4f8e-8d7e-6f075bea084c" },
  { description: "WHAT-10H45", id: "e853a2d7-1082-48be-ba7c-6e8788547d7d" },
  { description: "WHAT-11H10", id: "4db72409-041f-40e0-9470-cc2fbbbd2a1f" },
  { description: "WHAT-11H35", id: "6293b1fb-2888-430d-95ed-83079a0dcb19" },
  { description: "WHAT-02H40", id: "16f57ef0-3b8c-47fc-85d9-33f60a1f8d6d" },
  { description: "WHAT-03H05", id: "edf21f86-b17f-495e-babc-3986e3fa05e6" },
  { description: "WHAT-03H30", id: "265d1f46-4c81-4e9f-a549-8e7fcb60e266" },
  { description: "WHAT-03H55", id: "af047f74-8dd9-4b3e-af23-204116141260" },
  { description: "WHAT-04H20", id: "6cb67dda-aba0-4e6e-80af-296e1d169670" },
  { description: "WHAT-04H45", id: "52e7b988-37ce-4971-bc2c-e051708a1278" },
  {
    description: "ARGENTINA-COM-QUALITY-TRAVEL",
    id: "292e3d90-ac15-4d3e-bbaf-5e0055eb2e1e",
  },
  {
    description: "CARIBE-MEXICANO-COM-A-AT-TRAVEL",
    id: "d3124e63-b804-4ebd-89dc-bfb7706f3f14",
  },
  {
    description: "CARIBE-COM-DECAMERON-RESORTS",
    id: "f9c70e39-9866-4ada-acf5-240119c36b19",
  },
  {
    description: "CARIBE-COM-GRUPO-POSADAS",
    id: "c834a90d-3f14-4ef6-b350-8123d1d562a1",
  },
  {
    description: "CARIBE-COM-HILTON-ALL-INCLUSIVE",
    id: "80d23889-5625-4731-a60c-a836e8d72c0f",
  },
  {
    description: "CARIBE-COM-IBEROSTAR",
    id: "78dacf50-25fc-496b-a347-4403700d3612",
  },
  {
    description: "CARIBE-COM-MELIA-HOTELS",
    id: "3c515b61-2730-4275-aefe-692913f137b3",
  },
  {
    description: "CARIBE-COM-PALACE-RESORTS",
    id: "e5c80235-0a2f-4559-bab9-1f2f69c53e7e",
  },
  {
    description: "CARIBE-COM-PALLADIUM-RESORTS",
    id: "e947858e-de90-428f-9848-27f8500e47cd",
  },
  {
    description: "CARIBE-COM-PLAYA-RESORTS",
    id: "62d2a9b5-bce3-4d17-a174-34bd50284674",
  },
  {
    description: "CARIBE-COM-RCD-HOTELS",
    id: "45efd272-6164-4897-9dad-e70ead5161a7",
  },
  {
    description: "CARIBE-COM-SANDALS-RESORTS",
    id: "f39e80b8-c304-4d53-9135-a7934dd62c14",
  },
  {
    description: "CARIBE-COM-THE-FIVE-HOTELS",
    id: "61a4910d-4fa8-462b-8adf-ccb6230ace0a",
  },
  { description: "CHILE-COM-CTS", id: "1c0fbd0f-8316-48ae-9a15-939b9c87d295" },
  {
    description: "CHILE-COM-SERNATUR",
    id: "263e0304-3d5a-4d9f-8801-0548e49d4752",
  },
  {
    description: "CHINA-COM-ENJOY-CHINA-TOUR",
    id: "580968c6-ba60-4096-91d0-3747cae1cca1",
  },
  {
    description: "EUROPEUS-COM-SPECIAL-TOURS",
    id: "aee6eefd-798e-4fd0-8579-3ce58d409353",
  },
  {
    description: "EUROPEUS-COM-SURLAND",
    id: "71d4ac9b-283a-414c-afd7-b186578f0855",
  },
  {
    description: "COLOMBIA-COM-ALL-REPS",
    id: "b7532560-df0e-43ae-80a0-b3ac78ff962b",
  },
  { description: "CORIS-SEGUROS", id: "372c5b75-f704-46da-88b2-2598a24ca715" },
  {
    description: "CRUZEIROS-COM-NCL-NORWEGIAN-CRUISE-LINE",
    id: "53bac9c7-401b-4ff0-bc2e-f5985823b3e9",
  },
  {
    description: "CUBA-COM-ENJOY-TRAVEL-GROUP",
    id: "b3ac0bae-50dd-4640-9550-9a5bbced489e",
  },
  {
    description: "EGITO-COM-EXOTIC-TRAVEL",
    id: "c4061edb-e398-4f25-a40a-0bc57dd4cdb6",
  },
  {
    description: "ESPANHA-–-TURESPANA",
    id: "10b2c29e-82ab-4e91-b5db-df0befa7ea0f",
  },
  {
    description: "ESTADOS-UNIDOS---GREATER-MIAMI",
    id: "fe7d5cbf-42cc-431f-a14c-81fc1f4bf8c9",
  },
  {
    description: "ESTADOS-UNIDOS---HUDSON-YARDS-&-THE-EDGE-NYC",
    id: "4956fc10-952a-4a86-90e4-85002e574e81",
  },
  {
    description: "ESTADOS-UNIDOS---NOVA-YORK-TOURISM-AND-CONVENTION",
    id: "0b15a2b4-613b-4ce9-bacc-7272375bf434",
  },
  {
    description: "ESTADOS-UNIDOS---SAN-DIEGO",
    id: "9bc2d7ed-e74b-45da-a29c-60971c0cbe9f",
  },
  {
    description: "ESTADOS-UNIDOS---SAN-FRANCISCO",
    id: "1da11290-63c8-48f5-b5ed-2072862bf1c7",
  },
  {
    description: "ESTADOS-UNIDOS---SANTA-MONICA-TRAVEL",
    id: "e0a496bb-a954-4a04-acd8-057e4c39eb0d",
  },
  {
    description: "ESTADOS-UNIDOS---ST-PETE-&-CLEARWATER",
    id: "0a614d33-5814-446e-a9cf-e7d104b3c0f2",
  },
  {
    description: "ESTADOS-UNIDOS---SUL-DOS-ESTADOS-UNIDOS",
    id: "74d8553a-4c2e-49c3-a328-54e483071965",
  },
  {
    description: "ESTADOS-UNIDOS---VISIT-FLORIDA",
    id: "2eababc1-efdc-436d-951d-60d19339cf9a",
  },
  {
    description: "ESTADOS-UNIDOS---VISIT-ORLANDO",
    id: "6668cc34-dbb5-4d5d-b2b6-33b60469b3ea",
  },
  {
    description: "ESTADOS-UNIDOS---WASHINGTON-D.C",
    id: "55ba8274-2420-4f4e-8406-3304633880d1",
  },
  {
    description: "ESTADOS-UNIDOS-COM-BRAND-USA",
    id: "782daef8-cf75-488a-a0f3-299d8ba5fcbd",
  },
  {
    description: "ESTADOS-UNIDOS-COM-SOUTH-BEACH-GROUP-MIAMI",
    id: "42722834-d168-4f6e-8fd8-46003ecde376",
  },
  {
    description: "GRECIA-COM-RENAISSANCE-TOURS-&-TRAVEL",
    id: "be841d29-e177-4689-bccd-867ab1308bb1",
  },
  { description: "HERO-SEGUROS", id: "a507875a-216b-41ba-b806-0a57da1775c0" },
  {
    description: "ISRAEL-COM-CONEXION-TRAVEL-SAR-EL",
    id: "a20d9ae6-b4b4-4d74-9276-d6ef9e5d104a",
  },
  {
    description: "ITALIA-COM-CARRANI",
    id: "6e2fbbb7-afc6-40b6-b2cc-72e02a2e3104",
  },
  {
    description: "LOCACAO-DE-CARROS-COM-ALAMO-RENTAL-CARS",
    id: "3f4c584b-71bf-4237-9c7b-3370c98c1cdb",
  },
  {
    description: "MALDIVAS-COM-HERITANCE-E-ADAARAN",
    id: "9020a689-e0e4-46c9-b0e2-e2e3d20d7906",
  },
  {
    description: "MARROCOS-COM-VISIT-MARROCO-&-KTI",
    id: "04225ba6-87a8-4637-a209-cba3dd9fb2af",
  },
  {
    description: "MOTORHOMES-NOS-ESTADOS-UNIDOS-&-CANADA-COM-CRUISE-AMERICA",
    id: "1beaf29b-5ff8-4d4f-830b-63da32b11522",
  },
  {
    description: "NEPAL-E-BUTAO-COM-THE-HIMALAYA-TREKKING-COMPANY",
    id: "cdbe4107-fa41-479b-b0cb-450e669cd38c",
  },
  {
    description: "PANAMA-&-COPA-AIRLINES",
    id: "0153ad0f-02fe-4ed9-9a0f-92e2f5fbfaef",
  },
  { description: "PERU-COM-VIPAC", id: "a440723b-3168-4f6e-a852-ad82c0cf9c7e" },
  {
    description: "REINO-UNIDO-COM-ATS-GROUP",
    id: "a323a889-99c4-4904-9148-e96f9e3fda08",
  },
  {
    description: "REINO-UNIDO-COM-A-GJ-TRAVEL",
    id: "39a7abea-0614-4288-a346-761bb9be077a",
  },
  {
    description: "TURISMO-DA-JAMAICA",
    id: "44de9c6d-8a75-4dfa-9274-5dfb2864dacd",
  },
  {
    description: "TURISMO-DE-ARUBA-COM-VITRINE-GLOBAL",
    id: "717da7e5-1126-49f7-8289-f8b080313ff4",
  },
  {
    description: "TURISMO-DO-EGITO",
    id: "bb61d1c6-34f8-470f-a75a-5570fcaf32d8",
  },
  {
    description: "TURQUIA-COM-CRYSTAL-CONCEPTS",
    id: "5766bd9d-2fa5-4e0c-956f-b73018e77346",
  },
  {
    description: "URUGUAI-COM-ENJOY-PUNTA-DEL-ESTE",
    id: "dba7fad4-ae97-409f-a916-cc23e8a1169d",
  },
  {
    description: "VOE-AMERICAN-AIRLINES",
    id: "5e72efdc-9484-49be-8afc-539770576393",
  },
  {
    description: "VOE-ITA-AIRWAYS",
    id: "0efdb6ec-d946-4da4-b2d2-80911035891e",
  },
  {
    description: "VOE-SKY-AIRLINES",
    id: "98eac1ae-1f34-415f-98b8-22df8e6a2606",
  },
  {
    description: "VOE-TAP-AIRLINES",
    id: "64774ec2-e379-462a-88ae-a2ad3e68463b",
  },
  {
    description: "VOE-COM-AIR-EUROPE",
    id: "d16a9fb8-60b2-4b6c-9670-de0f07bc03de",
  },
];
